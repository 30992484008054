import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from '@solana/web3.js';

const WiFiControlPage = () => {
  // 1. Let user specify "ip:port"
  const [routerIpPort, setRouterIpPort] = useState('');
  const [routerConfigured, setRouterConfigured] = useState(false);

  // Other states
  const [isThrottlingEnabled, setIsThrottlingEnabled] = useState(false);
  const [throttleLevel, setThrottleLevel] = useState(50);
  const [throttleMode, setThrottleMode] = useState('Medium');
  const [loading, setLoading] = useState(true);
  const [earnings, setEarnings] = useState(0);
  const [updateStatus, setUpdateStatus] = useState('');
  const [walletAddress, setWalletAddress] = useState(null);
  const [scriptConnectionStatus, setScriptConnectionStatus] = useState('Unknown');
  const navigate = useNavigate();

  const earningsMultiplier = 0.05;

  // 2. Let user set the router IP. Then ping it
  const handleSetRouterIp = () => {
    if (routerIpPort.trim() !== '') {
      setRouterConfigured(true);
      checkScriptConnection(routerIpPort.trim());
    }
  };

  // 3. Check script
  const checkScriptConnection = async (ipPort) => {
    try {
      // Because Flask is on https with self-signed cert
      const response = await fetch(`https://${ipPort}/ping`, {
        // If you want to allow self-signed in dev, you can try 'mode: "no-cors"' or similar
        // but typically the user just needs to accept the cert in the browser
      });
      if (!response.ok) {
        throw new Error('Script ping failed');
      }
      setScriptConnectionStatus('Connected');

      // Write "Connected" to Firestore
      const user = auth.currentUser;
      if (user) {
        await setDoc(
          doc(db, 'users', user.uid),
          { wifiLimits: { scriptConnectionStatus: 'Connected' } },
          { merge: true }
        );
      }
    } catch (err) {
      console.error('Script connection error:', err);
      setScriptConnectionStatus('Not connected');

      // Firestore
      const user = auth.currentUser;
      if (user) {
        await setDoc(
          doc(db, 'users', user.uid),
          { wifiLimits: { scriptConnectionStatus: 'Not connected' } },
          { merge: true }
        );
      }
    }
  };

  // 4. Throttle
  const applyOpenWrtThrottle = async (download, upload) => {
    if (!routerIpPort) return; // user hasn't set IP
    try {
      const response = await fetch(`https://${routerIpPort}/set_throttle`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ download, upload }),
      });
      if (!response.ok) {
        throw new Error(`Failed to set throttle, status ${response.status}`);
      }
      console.log(`Throttle applied: download=${download}, upload=${upload}`);
    } catch (err) {
      console.error('Error applying throttle:', err);
      setUpdateStatus('Failed to apply bandwidth changes to router.');
    }
  };

  // 5. Load Firestore
  useEffect(() => {
    const fetchThrottlingSetting = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setIsThrottlingEnabled(data.wifiLimits?.throttlingEnabled || false);
          setThrottleLevel(data.wifiLimits?.throttleLevel || 50);
          setThrottleMode(data.wifiLimits?.throttleMode || 'Medium');
          setWalletAddress(data.walletAddress || null);

          calculateEarnings(data.wifiLimits?.throttleLevel || 50);
        }
      }
      setLoading(false);
    };
    fetchThrottlingSetting();
  }, []);

  // 6. Save settings
  const saveSettings = async () => {
    setUpdateStatus('Saving...');
    try {
      const user = auth.currentUser;
      if (user) {
        await setDoc(
          doc(db, 'users', user.uid),
          {
            wifiLimits: {
              throttlingEnabled: isThrottlingEnabled,
              throttleLevel,
              throttleMode,
            },
          },
          { merge: true }
        );

        if (isThrottlingEnabled) {
          const download = Math.floor((throttleLevel / 100) * 5000);
          const upload = Math.floor((throttleLevel / 100) * 1000);
          await applyOpenWrtThrottle(download, upload);
        } else {
          await applyOpenWrtThrottle(999999, 999999);
        }

        setUpdateStatus('Settings saved successfully!');
      }
    } catch (err) {
      console.error('Error updating throttling settings:', err);
      setUpdateStatus('Error saving settings.');
    }
  };

  // 7. Toggle throttling
  const toggleThrottling = async () => {
    setUpdateStatus(isThrottlingEnabled ? 'Disabling...' : 'Enabling...');
    try {
      const user = auth.currentUser;
      if (user) {
        const newState = !isThrottlingEnabled;
        await setDoc(
          doc(db, 'users', user.uid),
          {
            wifiLimits: {
              throttlingEnabled: newState,
              throttleLevel,
              throttleMode,
            },
          },
          { merge: true }
        );
        if (newState) {
          const download = Math.floor((throttleLevel / 100) * 5000);
          const upload = Math.floor((throttleLevel / 100) * 1000);
          await applyOpenWrtThrottle(download, upload);
        } else {
          await applyOpenWrtThrottle(999999, 999999);
        }

        setIsThrottlingEnabled(newState);
        setUpdateStatus(
          newState ? 'Throttling enabled' : 'Throttling disabled'
        );
      }
    } catch (err) {
      console.error('Error toggling throttling:', err);
      setUpdateStatus('Error updating throttling status.');
    }
  };

  // 8. Payment
  const payForBetterWiFi = async () => {
    if (!walletAddress) {
      setUpdateStatus('Connect a wallet to pay for better Wi-Fi.');
      return;
    }
    try {
      const provider = window.solana;
      if (provider && provider.isPhantom) {
        await provider.connect();
        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: provider.publicKey,
            toPubkey: new PublicKey('YOUR_RECEIVING_WALLET_ADDRESS'),
            lamports: LAMPORTS_PER_SOL * 0.01,
          })
        );
        const { signature } = await provider.signAndSendTransaction(transaction);
        await provider.connection.confirmTransaction(signature, 'confirmed');

        setIsThrottlingEnabled(false);
        await applyOpenWrtThrottle(999999, 999999);

        setUpdateStatus('Payment successful! Throttling disabled for 24 hours.');
      } else {
        setUpdateStatus('Please install a Solana wallet like Phantom.');
      }
    } catch (err) {
      console.error('Error processing payment:', err);
      setUpdateStatus('Error during payment. Please try again.');
    }
  };

  // 9. Slider
  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setThrottleLevel(value);
    updateThrottleMode(value);
    calculateEarnings(value);
    setUpdateStatus('');
  };

  const updateThrottleMode = (val) => {
    if (val < 33) setThrottleMode('Low');
    else if (val < 66) setThrottleMode('Medium');
    else setThrottleMode('High');
  };

  const calculateEarnings = (val) => {
    const calc = val * earningsMultiplier;
    setEarnings(calc.toFixed(2));
  };

  if (loading) {
    return <div style={loadingStyle}>Loading...</div>;
  }

  // 10. UI
  return (
    <div style={pageStyle}>
      <nav style={navStyle}>
        <div style={logoStyle}>WiBit</div>
      </nav>

      <div style={contentWrapperStyle}>
        <h1 style={headingStyle}>Wi-Fi Control</h1>

        {!routerConfigured && (
          <div style={{ marginBottom: '30px' }}>
            <p>Enter your router IP:port (e.g. 192.168.1.1:5000):</p>
            <input
              type="text"
              value={routerIpPort}
              onChange={(e) => setRouterIpPort(e.target.value)}
              placeholder="192.168.1.1:5000"
              style={{ padding: '10px', borderRadius: '5px', marginRight: '10px' }}
            />
            <button onClick={handleSetRouterIp} style={buttonStyle}>
              Set Router
            </button>
          </div>
        )}

        {routerConfigured && (
          <>
            <button onClick={toggleThrottling} style={buttonStyle}>
              {isThrottlingEnabled ? 'Disable Throttling' : 'Enable Throttling'}
            </button>

            {isThrottlingEnabled && (
              <div style={controlContainerStyle}>
                <h2 style={subHeadingStyle}>Throttling Level: {throttleMode}</h2>
                <div style={sliderContainerStyle}>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={throttleLevel}
                    onChange={handleSliderChange}
                    style={sliderStyle}
                  />
                  <div style={throttleDisplayStyle}>
                    {throttleLevel}% Throttling
                  </div>
                </div>

                <div style={earningsContainerStyle}>
                  <h3 style={earningsHeadingStyle}>Earnings Estimate</h3>
                  <p style={earningsTextStyle}>Earned Tokens: {earnings} tokens/day</p>
                  <p style={descriptionStyle}>
                    Throttle your Wi-Fi to earn more tokens. The higher the throttling,
                    the more tokens you earn.
                  </p>
                </div>

                <button onClick={payForBetterWiFi} style={payButtonStyle}>
                  Pay for Better Wi-Fi
                </button>

                <button onClick={saveSettings} style={saveButtonStyle}>
                  Save Settings
                </button>

                <button
                  onClick={() => navigate('/wallet-management')}
                  style={walletButtonStyle}
                >
                  Go to Wallet Management
                </button>

                {updateStatus && <div style={statusStyle}>{updateStatus}</div>}

                <div style={{ marginTop: '20px' }}>
                  <strong>Script Status:</strong> {scriptConnectionStatus}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// ----------------- STYLES -----------------
const pageStyle = {
  textAlign: 'center',
  minHeight: '100vh',
  color: '#fff',
  fontFamily: "'Roboto', sans-serif",
  backgroundColor: '#0D0D0D',
  position: 'relative',
  overflowX: 'hidden',
};

const navStyle = {
  position: 'fixed',
  top: 0,
  width: '100%',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 50px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 10,
};

const logoStyle = {
  fontSize: '1.8rem',
  color: '#8B5CF6',
  fontWeight: 'bold',
};

const contentWrapperStyle = {
  paddingTop: '100px',
  paddingBottom: '50px',
};

const headingStyle = {
  fontSize: '3.5rem',
  marginBottom: '20px',
  background: 'linear-gradient(90deg, #A855F7, #8B5CF6)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '2px',
};

const subHeadingStyle = {
  fontSize: '2rem',
  marginBottom: '10px',
  color: '#FFD700',
};

const controlContainerStyle = {
  marginTop: '30px',
  textAlign: 'center',
};

const sliderContainerStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const sliderStyle = {
  width: '80%',
  maxWidth: '500px',
  marginTop: '20px',
  accentColor: '#A855F7',
};

const throttleDisplayStyle = {
  marginTop: '10px',
  fontSize: '1.5rem',
  color: '#A855F7',
};

const earningsContainerStyle = {
  marginTop: '30px',
  padding: '20px',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '10px',
  maxWidth: '600px',
  margin: 'auto',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
};

const earningsHeadingStyle = {
  fontSize: '1.8rem',
  color: '#FFD700',
};

const earningsTextStyle = {
  fontSize: '1.5rem',
  color: '#fff',
};

const descriptionStyle = {
  fontSize: '1.2rem',
  color: '#ddd',
  marginTop: '20px',
  padding: '0 30px',
  maxWidth: '600px',
  margin: 'auto',
};

const buttonStyle = {
  padding: '15px 30px',
  fontSize: '1.2rem',
  borderRadius: '10px',
  border: 'none',
  background: 'linear-gradient(90deg, #8B5CF6, #A855F7)',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
  marginTop: '20px',
};

const saveButtonStyle = {
  ...buttonStyle,
  background: 'linear-gradient(90deg, #34D399, #10B981)',
};

const walletButtonStyle = {
  ...buttonStyle,
  background: 'linear-gradient(90deg, #F59E0B, #D97706)',
  marginLeft: '10px',
};

const payButtonStyle = {
  ...buttonStyle,
  background: 'linear-gradient(90deg, #F472B6, #EC4899)',
};

const statusStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#FFD700',
  borderRadius: '10px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
};

const loadingStyle = {
  textAlign: 'center',
  color: '#fff',
  fontSize: '1.5rem',
  marginTop: '50px',
};

const styles = `
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
  .button:hover {
    transform: translateY(-3px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default WiFiControlPage;